import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Container } from '../components/container'
import { SectionTitle } from '../components/section-title'
import { useTranslation } from 'react-i18next'

const ManualPage = ({ data }) => {
  const updatedLinks = data.links.nodes.reduce(
    (newObj, item) => ((newObj[item.locale] = item.slug), newObj),
    {}
  )
  const {title, contentNode, seoMetaTags} = data.manual

  return (
    <Layout hrefLangLinks={updatedLinks} seoMetaTags={seoMetaTags}>
      <Container color="bg-beige">
        <SectionTitle title={title} centered={true} main={true} />
        <div
          dangerouslySetInnerHTML={{ __html: contentNode.childMarkdownRemark.html }}
          className="prose prose-sm lg:prose max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-6"
        ></div>
      </Container>
    </Layout>
  )
}

export default ManualPage

export const manualDetailQuery = graphql`
  query ($id: String!, $originalId: String!) {
    manual: datoCmsManual(id: { eq: $id }) {
      title
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    links: allDatoCmsManual(filter: { originalId: { eq: $originalId } }) {
      nodes {
        slug
        locale
      }
    }
  }
`
